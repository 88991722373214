import React, { useEffect, useState,useContext  } from "react";
import { Col, Row } from "react-bootstrap";
import { FaRegTrashAlt } from "react-icons/fa";
import Aos from "aos";
import { GlobalContext } from "../../../../../components/GlobalContext";
function QuoteCard({ equipmentList, setEquipmentList, isVisible }) {
  const [isClosing, setIsClosing] = useState(false);
  const [shouldRender, setShouldRender] = useState(isVisible);
  const { setQuoteMessage } = useContext(GlobalContext);
  const { quoteMessage } = useContext(GlobalContext);

  const handleRemove = (index) => {
    setEquipmentList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const groupedEquipments = equipmentList.reduce((acc, item) => {
    acc[item.titulo] = (acc[item.titulo] || 0) + 1;
    return acc;
  }, {});

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    if (isVisible) {
      setShouldRender(true);
    } else {
      setIsClosing(true);
      setTimeout(() => {
        setIsClosing(false);
        setShouldRender(false);
      }, 600); // Duración de la animación de salida
    }
  }, [isVisible]);

  if (!shouldRender) {
    return null;
  }
 
  const handleQuote = () => {
    const message = "Hola, buenas tardes:\n\nQuisiera solicitar una cotización para los siguientes equipos:\n\n";
    const equipmentMessage = Object.keys(groupedEquipments)
      .map((titulo) => `${titulo}`)
      .join("\n");

    const fullMessage = `${message}${equipmentMessage}\n\nGracias y que tenga una buena tarde.`;

    setQuoteMessage(fullMessage);
  };
  return (
    <div
      className={`quote-card-container ${isClosing ? 'closing' : 'open'}`}
      data-aos="fade-up"
      data-aos-duration="600"
    >
      <div className="mb-3 mt-3">
        <h4>Equipos para cotizar</h4>
      </div>
      <div className="quote-content">
        {Object.keys(groupedEquipments).length > 0 ? (
          Object.keys(groupedEquipments).map((titulo, index) => (
            <Row key={index} className="item" data-aos="fade-up" data-aos-duration="600">
              <Col md="4" xs="4">
                {equipmentList.find((item) => item.titulo === titulo) && (
                  <img
                    src={equipmentList.find((item) => item.titulo === titulo).foto}
                    alt="Equipo"
                  />
                )}
              </Col>
              <Col md="8" xs="8">
                <div className="d-flex justify-content-between">
                  <strong>{titulo}</strong>
                  <a
                    className="remove-item"
                    onClick={() => handleRemove(equipmentList.findIndex((item) => item.titulo === titulo))}
                  >
                    <FaRegTrashAlt />
                  </a>
                </div>
                <p>{equipmentList.find((item) => item.titulo === titulo).modelo}</p>
              </Col>
            </Row>
          ))
        ) : (
          <p>No hay equipos en la cotización.</p>
        )}
      </div>
      <Row className="mt-3">
        <a className="btn primary" onClick={()=> handleQuote()} href="#contactus">Realizar cotización de los equipos</a>
      </Row>
    </div>
  );
}

export default QuoteCard;
