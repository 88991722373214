import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import ContactForm from "../../../../components/contact_form/contact_form";
import { IoMailOutline, IoMapOutline } from "react-icons/io5";
import Aos from "aos";
import { useEffect,useContext } from "react";
import { GlobalContext } from "../../../../components/GlobalContext";
function ContactUs() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <section
      className="contact-us"
      id="contactus"
      style={{ paddingBottom: "0" }}
    >
      <Container>
        <Row>
          <Col sm="6" data-aos="fade-up"
          data-aos-duration="800">
            <span>
              <img
                src="img/icons/arrow.png"
                style={{ width: "25px", marginRight: "10px" }}
              ></img>
              Contáctanos
            </span>
            <h1>Contáctanos</h1>
            <p>
              Estamos aquí para escucharte y ayudarte en lo que necesites. No
              dudes en ponerte en contacto con nosotros para discutir tus
              proyectos, resolver tus dudas o recibir asesoramiento profesional.
            </p>
            <p>
              Nuestro equipo de expertos está listo para brindarte soluciones a
              medida y asistencia personalizada. Ya sea por teléfono, correo
              electrónico o en persona, estamos comprometidos a proporcionarte
              el mejor servicio posible.
            </p>
          </Col>
          <Col
            sm="6"
            className="contact-card"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <ContactForm
              placeHolder_nombre={"Nombre"}
              placeHolder_btn={"Contáctanos"}
              placeHolder_correo={"Correo"}
              placeHolder_mensaje={"Mensaje"}
              placeHolder_telefono={"Teléfono"}
            />
          </Col>
        </Row>

      </Container>
      <Container className="mt-5">
      <div className="row">
          <div
            className="col-md-4  col-sm-12 col-lg-4"
            style={{
              paddingRight: "0",
              paddingLeft: "0",
              zIndex: 100,
              minHeight: "auto",
            }}
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <div className="card-solution">
              <div className="d-flex">
                <h3 className="contact-icon">{<IoMailOutline />}</h3>
                <div style={{ marginLeft: "15px" }}>
                  <h3>Envíanos Correo</h3>
                  <p>Contacto@r3rentas.com</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-4  col-sm-12 col-lg-4"
            style={{
              paddingRight: "0",
              paddingLeft: "0",
              zIndex: 100,
              minHeight: "auto",
            }}
            data-aos="fade-up"
            data-aos-duration="900"
          >
            <div className="card-solution">
              <div className="d-flex">
                <h3 className="contact-icon">{<IoMapOutline/>}</h3>
                <div style={{ marginLeft: "15px" }}>
                  <h3>Ubícanos</h3>
                  <p>
                    Blvr. Luis Donaldo Colosio 2680-Local 11, Kennedy, 84063
                    Heroica Nogales, Son.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-4  col-sm-12 col-lg-4"
            style={{
              paddingRight: "0",
              paddingLeft: "0",
              zIndex: 100,
              minHeight: "auto",
            }}
            data-aos="fade-up"
            data-aos-duration="1100"
          >
            <div className="card-solution">
              <div className="d-flex">
                <h3 className="contact-icon">{<IoMapOutline/>}</h3>
                <div style={{ marginLeft: "15px" }}>
                  <h3>Llámanos</h3>
                  <p><strong>Oficina </strong> +52 6312092129</p>
                  <p><strong>WhatsApp: </strong> +52 6311095989</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div style={{ height: "450px", width: "100%" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3218.255021900385!2d-110.94892052391552!3d31.29162365840705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xaafdd1bec3274033%3A0xd4a0d316093fe733!2sR3%20Ingenieria!5e0!3m2!1ses-419!2smx!4v1712635964592!5m2!1ses-419!2smx"
          loading="lazy"
          height={"100%"}
          width={"100%"}
        ></iframe>
      </div>
    </section>
  );
}

export default ContactUs;
