import React, { useState, useRef } from "react";
import { IoCloseSharp, IoArrowForward, IoArrowBack } from "react-icons/io5";

const filterData = [
  "Todos",
  "Plataforma de Tijera",
  "Compactadora",
  "Revolvedora",
  "Demoledor",
  "Rotomartillo",
  "Generador",
  "Compresor",
  "Cortadora",
  "Esmeriladora",
  "Vibrador de Concreto",
];

function Filters({ onFilter }) {
  const [selectedFilter, setSelectedFilter] = useState("Todos");
  const [showFilters, setShowFilters] = useState(true);
  const filterRef = useRef(null);

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
    onFilter(filter);
    setShowFilters(false);
  };

  const handleCloseClick = () => {
    setShowFilters(true);
    setSelectedFilter("Todos");
    onFilter("Todos");
  };

  const scroll = (direction) => {
    if (filterRef.current) {
      const scrollAmount = direction === "left" ? -200 : 200;
      filterRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };
  const containerStyle = {
    marginRight: showFilters ? "40px" : "0px",
    marginLeft: showFilters ? "50px" : "0px",
  };
  return (
    <div style={{ position: "relative", width: "100%" }}>
      {showFilters && (
        <div className="arrow-filter-container">
          <button className="btn-filter btn arrow-filter" onClick={() => scroll("left")}>
            <IoArrowBack />
          </button>
          <button className="btn-filter btn arrow-filter" onClick={() => scroll("right")}>
            <IoArrowForward />
          </button>
        </div>
      )}
      <div className="d-flex scroll-filter" ref={filterRef} style={containerStyle}>
        {!showFilters && (
          <a className="btn-filter btn" onClick={handleCloseClick}>
            <IoCloseSharp />
          </a>
        )}
        {showFilters ? (
          filterData.map((item, index) => (
            <a
              key={index}
              className={`btn-filter btn ${selectedFilter === item ? "active" : ""}`}
              onClick={() => handleFilterClick(item)}
            >
              {item}
            </a>
          ))
        ) : (
          <a className="btn-filter btn active">{selectedFilter}</a>
        )}
      </div>
    </div>
  );
}

export default Filters;
