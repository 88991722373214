import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const NavbarComponent = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [activeLink, setActiveLink] = useState("/#home"); // Estado para el ítem activo

  const toggleMenu = () => {
    setShowMenu((prevShowMenu) => !prevShowMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSticky(offset > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const hash = window.location.hash.substr(1);
    if (hash) {
      scrollToSection(hash);
    }
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLinkClick = (href) => {
    setActiveLink(href);
    closeMenu();
  };

  const logo = "img/logo/logo.svg";

  return (
    <>
      <div
        className={`navbar absoulteNav ${
          isSticky ? "sticky " : "navbar-nosticky"
        }`}
      >
        <div className="container content">
          <div className="logo-navbar-responsive">
            <a href="/#home">
                <img
                  src={logo}
                  style={{ height: "3rem", marginLeft: "0" }}
                  alt="Logo"
                />
              </a>
          </div>
          <div className={`navbar-content ${showMenu ? "show" : ""}`}>
            <div className="navbar-display " style={{ width: "100%" }}>
              <div className="logo-navbar">
                <a href="/#home">
                  <img
                    src={logo}
                    style={{ height: "4rem", marginLeft: "0" }}
                    alt="Logo"
                  />
                </a>
              </div>
              <div
                className="d-flex justify-content-end"
                style={{ width: "100%" }}
              >
                <div className="navbar-content-responsive">
                  <a
                    href="/#home"
                    onClick={() => handleLinkClick("/#home")}
                    className={activeLink === "/#home" ? "active" : ""}
                  >
                    Inicio
                  </a>
                  <a
                    href="/#about"
                    onClick={() => handleLinkClick("/#about")}
                    className={activeLink === "/#about" ? "active" : ""}
                  >
                    Nosotros
                  </a>
                  {/*<a
                    href="/#services"
                    onClick={() => handleLinkClick("/#services")}
                    className={activeLink === "/#services" ? "active" : ""}
                  >
                    Servicios
                  </a>*/}
                  <a
                    href="#equipment"
                    onClick={() => handleLinkClick("#equipment")}
                    className={activeLink === "#equipment" ? "active" : ""}
                  >
                    Equipos
                  </a>
                  <a
                    href="#contactus"
                    onClick={() => handleLinkClick("#contactus")}
                    className={activeLink === "#contactus" ? "active" : ""}
                  >
                    Contáctanos
                  </a>
                </div>
              </div>
              {/*<div className="d-flex justify-content-end btn-navbar">
                <a className="btn primary btn-navbar" href="#contactus">Cotizar</a>
              </div>*/}
            </div>
          </div>
          <div
            className="mobile-menu-toggle justify-content-end"
            onClick={toggleMenu}
            style={{ marginLeft: "auto" }}
          >
            <div className={`bar ${showMenu ? "open" : ""}`}></div>
            <div className={`bar ${showMenu ? "open" : ""}`}></div>
            <div className={`bar ${showMenu ? "open" : ""}`}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarComponent;
