import NavbarComponent from "../../components/Navbar";
import About from "./sections/about/about";
import ContactUs from "./sections/contact_us/contact_us";
import Equipment from "./sections/equipment/equipment";
import Footer from "./sections/footer/footer";
import FooterCopy from "./sections/footer_copy/footer_copy";
import Header from "./sections/header/header"
import Services from "./sections/services/services";
function MainPage() {
    return (
      <>
        <NavbarComponent/>
        <Header/>
        <About/>
        {/*<Services/>*/}
        <Equipment/>
        <ContactUs/>
        <Footer/>
        <FooterCopy/>
      </>
    );
  }
  
  export default MainPage;