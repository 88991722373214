import { Container, Col } from "react-bootstrap";
import Aos from "aos";
import { useEffect } from "react";
function Header() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="hero" id="home">
      <Container className="content-hero" style={{ zIndex: "500" }}>
        <Col sm="10" className="card-hero" data-aos="fade-up" data-aos-duration="800">
          <div className="line-yellow">
            <div style={{ marginLeft: "15px" }}>
              <h1 style={{ textTransform: "uppercase" }}>
                Renta de Maquinaria Ligera en Nogales - Eficiencia y Calidad con
                R3 Rentas
              </h1>
            </div>
          </div>
          <p>
            Opta por R3 Rentas para la renta de maquinaria ligera en Nogales,
            Sonora. Nuestra flota de equipos está diseñada para brindar la
            máxima eficiencia y calidad en cada proyecto.
          </p>
          <div className="hero-btn-section">
            <a className="btn primary" href="#equipment">
              Nuestros Equipos
            </a>

            <a className="btn third" href="#contactus">
              Contáctanos
            </a>
          </div>
        </Col>
      </Container>
     <div className="video-wrapper">
      <div className="video-overlay"></div>
        <video autoPlay loop muted playsInline>
          <source src="img/header/header_1.webm"></source>
        </video>
      </div>
    </div>
  );
}

export default Header;
