import { Row, Col } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";

function EquipmentCard({
  titulo,
  marca,
  modelo,
  aditamento,
  precio,
  foto,
  addQuote,
  dimensiones,
}) {
  const getInfoEquipment = {
    titulo: titulo,
    marca: marca,
    modelo: modelo,
    aditamento: aditamento,
    precio: precio,
    foto: foto,
    dimensiones: dimensiones,
  };

  const sendToAddQuote = () => {
    addQuote(getInfoEquipment);
  };

  return (
    <div className="card-solution d-flex flex-column h-100">
      <Row>
        <h4>{titulo}</h4>
      </Row>
      <div className="flex-grow-1">
        <Row>
          <Col xs="6" sm="auto" lg="5" md="5">
            <div className="img-container">
              <img src={foto} className="img-fluid" />
            </div>
          </Col>
          <Col lg="7" md="7" sx="6">
            <p>
              <strong>Marca:</strong> {marca}
            </p>
            <p>
              <strong>Modelo:</strong> {modelo}
            </p>
            <p>
              <strong>Caracteristicas:</strong> {aditamento}
            </p>
            <p>
              <strong>Dimensiones:</strong> {dimensiones}
            </p>
          </Col>
        </Row>
      </div>
      <Row className="mt-auto">
        <div className="d-flex align-items-end w-100">
          <div className="d-flex flex-column flex-md-row gap-2 w-100">
            {/* Botón de WhatsApp */}
            <a
              href="https://wa.me/526311095989"
              target="_blank"
              rel="noopener noreferrer"
              className="btn primary w-100 w-sm-auto"
            >
              <BsWhatsapp className="fs-4" />
              <div className=" ms-2">WhatsApp</div>
            </a>

            {/* Botón de Teléfono */}
            <a href="tel:+526311095989" className="btn primary w-100 w-sm-auto">
              <FaPhoneAlt className="fs-5" />
              <div className=" ms-2">Llamar</div>
            </a>

            {/* Botón de Agregar a Cotización */}
            <button className="btn primary w-100" onClick={sendToAddQuote}>
              Agregar a cotización
            </button>
          </div>
        </div>
      </Row>
    </div>
  );
}

export default EquipmentCard;
