import { Col, Container, Row } from "react-bootstrap";
import Aos from "aos";
import { useEffect } from "react";

function About() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <section id="about" className="about">
      <Container>
        <Row>
          <Col xs="12" md="6" className="order-2 order-md-1" data-aos="fade-up"
          data-aos-duration="800"> 
            <img
              src="img/about/about_1.jpg"
              alt="About"
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs="12" md="6" className="order-1 order-md-2" data-aos="fade-up"
          data-aos-duration="800">
            <span className="mb-3">
              <img
                src="/img/icons/arrow.png"
                style={{ width: "25px", marginRight: "10px" }}
                alt="Arrow"
              />
              ¿Quiénes somos?
            </span>
            <h2>
              Excelencia en Alquiler de Equipos de Construcción
            </h2>
            <p className="mt-4">
              En R3 Rentas, somos especialistas en la renta de maquinaria ligera
              en Nogales, Sonora, ofreciendo soluciones de alta calidad para la
              construcción y la ingeniería. Fundada en 2009, nuestra empresa
              hermana, R3 Ingeniería, ha demostrado un compromiso sólido con la
              excelencia en la industria, y en R3 Rentas extendemos esa
              experiencia al mercado de alquiler de equipos.
            </p>
            <p>
              Nuestro objetivo es proporcionar a nuestros clientes maquinaria
              confiable y eficiente, diseñada para optimizar la ejecución de
              proyectos y garantizar resultados exitosos. Nos enorgullece apoyar
              a nuestros clientes con el mejor equipo disponible y un servicio
              personalizado.
            </p>
          </Col>
          <div className="d-flex justify-content-end order-3 order-md-3" data-aos="fade-up" data-aos-duration="800">
            <a className="btn primary" href="#contactus">Contáctanos</a>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default About;
