import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import MainPage from './pages/main_page/main_page';
import NotFound from './pages/not_found/not_found';
import { GlobalProvider } from './components/GlobalContext';
function App() {
  return (
    <GlobalProvider>
    <div >
      <Router>
        <Routes>
          <Route exact path="/" element={<MainPage/>}></Route>
          <Route exact path="/*" element={<NotFound/>}></Route>
        </Routes>
      </Router>
    </div>
    </GlobalProvider>
  );
}

export default App;